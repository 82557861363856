import React from 'react';

export default function DeleteAccountPage() {
    const handleEmailClick = () => {
        window.location.href = "mailto:support@scrapcart.com?subject=Account Deletion Request";
    };

    return (
        <div className="mx-auto max-w-2xl px-4 py-8">
            <div className="rounded-lg border border-gray-200 bg-white p-6 shadow-sm">
                <div className="mb-6">
                    <h1 className="text-2xl font-bold text-gray-900">How to Delete Your Account</h1>
                </div>

                <div className="space-y-6">
                    <p className="text-gray-600">
                        To delete your account and all associated data, please follow these steps:
                    </p>

                    <ol className="ml-6 list-decimal space-y-3 text-gray-700">
                        <li>Open the app</li>
                        <li>
                            Navigate to <span className="font-medium">Settings &gt; Account</span>
                        </li>
                        <li>
                            Tap <span className="font-medium">Delete Account</span> and confirm
                        </li>
                        <li>Your account will be permanently deleted within 30 days</li>
                    </ol>

                    <div className="rounded-md bg-amber-50 p-4 text-sm text-amber-800">
                        This action cannot be undone. Once your account is deleted, all your data will be permanently removed from our
                        systems.
                    </div>
                </div>

                <div className="mt-8 flex flex-col gap-4 sm:flex-row sm:items-center">
                    <button
                        className="flex items-center justify-center gap-2 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 transition-colors hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                        type="button"
                        onClick={handleEmailClick}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="size-4"
                        >
                            <rect width="20" height="16" x="2" y="4" rx="2" />
                            <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
                        </svg>
                        Contact Support
                    </button>
                </div>
            </div>
        </div>
    )
}  